import ActionTypes from "@redux/actionsTypes";
import { userType } from "./types";
const initialState: userType = {
  token: null,
};
export const auth = (
  state: userType = initialState,
  action: any
): userType => {
  switch (action.type) {
    case ActionTypes.auth.SAVE_TOKEN:
      return { ...state, token: action.payload };
    default:
      return state;
  }
};
