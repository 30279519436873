import ActionTypes from "@redux/actionsTypes";
import { VideoModal } from "./types";
const initialState: VideoModal = {
  show: false,
};
export const Video = (
  state: VideoModal = initialState,
  action: any
): VideoModal => {
  switch (action.type) {
    case ActionTypes.Video.TOGGLE_MODAL:
      let show = state.show
      return {
        show: !show,
      };
    default:
      return state;
  }
};
