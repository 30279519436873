import React, { useState } from "react";
import { IProps } from "./types";
import Link from "next/link";
import Router from "next/router";

const MenuItem = (props: any) => {
  return (
    <span
      onClick={() => {
        if (props.props) {
          props.props.setExpanded();
        }
        if (props.props.pathname) {
          Router.push(props.props.pathname);
        } else {
          Router.push(
            {
              pathname: "/",
              query: { section: props.props.section },
            },
            undefined,
            { shallow: true }
          );
        }
      }}
      className="w-full border-b border-gray-200 px-6 capitalize py-3.5 cursor-pointer font-sans-pro-semi-bold color-150910-secondary-1 font-semibold font-size-18"
    >
      {props.children}
    </span>
  );
};

const Mobile = (props: IProps) => {
  const [loading, setLoading] = useState(false);
  return <>
    <div className="flex flex-1 lg:hidden" />
    <div
      onClick={() => props.setExpanded()}
      className="flex flex-row lg:hidden"
    >
      <div className="flex flex-row items-center">
        <span className="mr-2 font-semibold font-size-12 font-sans-pro-semi-bold color-636363-secondary-2">
          {props.expanded ? "Close menu" : "Menu"}
        </span>
        {props.expanded ? (
          <img src={"https://cdn.remotederm.ca/icons/header/menu.webp"} alt="Main Menu" className="w-7 h-7" />
        ) : (
          <img src={"https://cdn.remotederm.ca/icons/header/menu.webp"} alt="Main Menu" className="w-7 h-7" />
        )}
      </div>
    </div>
    {props.expanded && (
      <div
        className={` w-full shadow-md rounded-b-xl mt-20 bg-white left-0 flex flex-col  transition-all duration-500 lg:hidden z-10 absolute h-auto  ${
          props.expanded ? "opacity-100 top-0" : "opacity-0 -top-1/3"
        } overflow-hidden`}
      >
        <MenuItem props={{ ...props, pathname: "/" }}>{"home"}</MenuItem>
        {/*<MenuItem props={{ ...props, pathname: "/pages/services" }}>
          {"Services"}
      </MenuItem>*/}
        <MenuItem props={{ ...props, pathname: "/conditions" }}>
          {"Conditions"}
        </MenuItem>
        <MenuItem props={{ ...props, pathname: "/locations" }}>
          {"Locations"}
        </MenuItem>
        <MenuItem props={{ ...props, pathname: "/pages/how-it-works" }}>
          {"How It Works"}
        </MenuItem>
        <MenuItem props={{ ...props, pathname: "/pages/about-us" }}>
          {"About Us"}
        </MenuItem>
        <MenuItem props={{ ...props, pathname: "/pages/pricing" }}>
          {"Pricing"}
        </MenuItem>
        <MenuItem props={{ ...props, pathname: "/pages/our-team" }}>
          {"Our Team"}
        </MenuItem>
        <MenuItem props={{ ...props, pathname: "/pages/faq" }}>
          {"FAQ"}
        </MenuItem>
        <a
          href={"https://remotederm.ca/blog/"}
          target="_blank"
          onClick={() => props.setExpanded()}
          className="w-full border-b border-gray-200 px-6 capitalize py-3.5 cursor-pointer font-sans-pro-semi-bold color-150910-secondary-1 font-semibold font-size-18"
        >
          {"Blog"}
        </a>
        {props.user ? (
          <div className="flex flex-col my-6 mx-6">
            <div className="flex flex-row mb-4">
              <img
                className="h-10 w-10 mr-3 cursor-pointer rounded-full"
                alt="User avatar"
                src={
                  props.user.avatar
                    ? props.user.avatar
                    : "https://cdn.remotederm.ca/icons/header/no_profile_photo.webp"
                }
              />
              <div className="flex flex-col">
                <span className="font-sans-pro-regular font-size-16 color-150910-secondary-1 mb-1">
                  {props.user?.email}
                </span>
                <span className="font-sans-pro-regular font-size-12 color-636363-secondary-2">{`${props.user?.firstName} ${props.user?.lastName}`}</span>
              </div>
            </div>
            <div
              onClick={() => {
                setLoading(true);
                window.location.href = process.env.REACT_APP_PATIENT_URL!;
                setTimeout(() => {
                  setLoading(false);
                }, 2000);
              }}
              className={`bg-black cursor-pointer mb-4 h-10 w-full border border-black rounded-lg flex justify-center items-center text-white font-size-12`}
            >
              {loading ? (
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <span>{"Go to panel"}</span>
              )}
            </div>
            <div
              onClick={() => props.onLogout()}
              className={`${
                props.loading ? "bg-black" : ""
              } cursor-pointer h-10 w-full border border-gray-200 rounded-lg flex justify-center items-center color-150910-secondary-1 font-size-12`}
            >
              {props.loading ? (
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <span>{"Logout"}</span>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center my-6 mx-6">
            <Link href="/pages/auth?type=login" legacyBehavior>
              <span
                onClick={() => props.setExpanded()}
                className="cursor-pointer px-6 py-3.5 mr-4 font-sans-pro-semi-bold color-150910-secondary-1 font-semibold font-size-14"
              >
                {"Login"}
              </span>
            </Link>
            <Link href="/pages/auth?type=register" legacyBehavior>
              <span
                onClick={() => props.setExpanded()}
                className="bg-color-150910-secondary-1 cursor-pointer flex-1 py-3.5 rounded-xl text-white text-center font-semibold font-size-14 font-sans-pro-semi-bold"
              >
                {"Sign Up"}
              </span>
            </Link>
          </div>
        )}
      </div>
    )}
  </>;
};

export default Mobile;
