const URLS = {
  AUTH: {
    login: "/tokens/patients",
    register: "/patients",
    userInfo: "/patients",
    verifyAccount: "/patients",
    authGoogle: "/tokens/googles",
    authFacebook: "/tokens/facebooks",
    logout: "/tokens",
    forgetPassword: "/password_tokens",
    resetPassword: "/passwords",
  },
  HOME: {
    comments: "/testimonials",
    plans: "/plans",
    blogs: "/blogs",
    locations: "/locations",
    contact: "/contact_us",
  },
  FAQ: {
    categories: '/faq_categories?',
    getList: '/faqs',
  },
  TEAM: {
    dermatologists : '/public_dermatologists'
  }
};

export default URLS;
