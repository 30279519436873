import React, { useState } from "react";
import Link from "next/link";
import { THeaderItem, IProps } from "./types";
import { useRouter } from "next/router";

const Desktop = (props: IProps) => {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  function checkUrl(router: any, url: string) {
    if (router.asPath === url) {
      return true;
    }
    return false;
  }

  const HeaderItem = (props: THeaderItem) => {
    return (
      (<Link
        href={props.url as string}
        className={`${
          checkUrl(router, props.url as string)
            ? "border-black"
            : "border-transparent"
        } pb-2 -mb-2 border-b  capitalize ${
          props?.last ? "mr-0" : "mr-3 xl:mr-8"
        }  cursor-pointer font-sans-pro-semi-bold color-150910-secondary-1 font-semibold font-size-14`}>

        {props.children}

      </Link>)
    );
  };

  return (
    <>
      <div className="hidden lg:flex flex-1 flex-row items-center justify-center">
        <HeaderItem url={"/"}>
          <div className="cursor-pointer ml-3">{"home"}</div>
        </HeaderItem>
        {/*<HeaderItem url={"/pages/services"}>
          <div className="cursor-pointer">{"Services"}</div>
        </HeaderItem>*/}
        <HeaderItem url={"/conditions"}>
          <div className="cursor-pointer">{"Conditions"}</div>
        </HeaderItem>
        <HeaderItem url={"/locations"}>
          <div className="cursor-pointer">{"Locations"}</div>
        </HeaderItem>
        <HeaderItem url={"/pages/how-it-works"}>
          <div className="cursor-pointer">{"How It Works"}</div>
        </HeaderItem>
        <HeaderItem url={"/pages/about-us"}>
          <div className="cursor-pointer">{"About Us"}</div>
        </HeaderItem>
        <HeaderItem url={"/pages/our-team"}>
          <div className="cursor-pointer">{"Our Team"}</div>
        </HeaderItem>
        <HeaderItem url={"/pages/pricing"}>
          <div className="cursor-pointer">{"Pricing"}</div>
        </HeaderItem>
        <HeaderItem url={"/pages/faq"}>
          <div className="cursor-pointer">{"FAQ"}</div>
        </HeaderItem>
        <a href={"https://remotederm.ca/blog/"} target="_blank">
          <div className="cursor-pointer  pb-2 -mb-2  capitalize mr-0  cursor-pointer font-sans-pro-semi-bold color-150910-secondary-1 font-semibold font-size-14">
            {"Blog"}
          </div>
        </a>
      </div>
      <div className="hidden lg:flex flex-row items-center relative">
        {props.user ? (
          <>
            <img
              alt="Help icon"
              className="h-7 w-7 mr-5 cursor-pointer"
              src={"https://cdn.remotederm.ca/icons/header/help.webp"}
            />
            <img
              alt="Notification icon"
              className="h-7 w-7 mr-5 cursor-pointer"
              src={"https://cdn.remotederm.ca/icons/header/notification_read.webp"}
            />
            <div className="relative">
              <img
                onClick={() => setExpanded(!expanded)}
                className="h-10 w-10 cursor-pointer  rounded-full"
                alt="Profile photo"
                src={
                  props.user.avatar
                    ? props.user.avatar
                    : "https://cdn.remotederm.ca/icons/header/no_profile_photo.webp"
                }
              />
              {expanded && (
                <div className="hidden lg:block rounded-lg absolute right-3  top-10 bg-white shadow-lg py-10 px-6 pb-4">
                  <div className="flex flex-col">
                    <span className="font-sans-pro-regular font-size-16 color-150910-secondary-1 mb-1">
                      {props.user?.email}
                    </span>
                    <span className="font-sans-pro-regular font-size-12 color-636363-secondary-2">{`${props.user?.firstName} ${props.user?.lastName}`}</span>
                  </div>
                  <div
                    onClick={() => {
                      setLoading(true);
                      window.location.href = process.env.REACT_APP_PATIENT_URL!;
                      setTimeout(() => {
                        setLoading(false);
                      }, 2000);
                    }}
                    className={`bg-black cursor-pointer mt-10 mb-4 h-10 w-full border border-black rounded-lg flex justify-center items-center text-white font-size-12`}
                  >
                    {loading ? (
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <span>{"Go to panel"}</span>
                    )}
                  </div>
                  <div
                    onClick={() => props.onLogout()}
                    className={`${
                      props.loading ? "bg-black" : ""
                    }  cursor-pointer h-10 w-full border border-gray-200 rounded-lg flex justify-center items-center color-150910-secondary-1 font-size-12`}
                  >
                    {props.loading ? (
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <span>{"Logout"}</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <span className="cursor-pointer px-6 py-3.5 mr-4 font-sans-pro-semi-bold color-150910-secondary-1 font-semibold font-size-14">
              <div
                onClick={() => {
                  router.push({
                    pathname: "/pages/auth",
                    query: { type: "login" },
                  });
                }}
              >
                {"Login"}
              </div>
            </span>
            <span className="bg-color-150910-secondary-1 cursor-pointer px-6 py-3.5 rounded-xl text-white font-semibold font-size-14 font-sans-pro-semi-bold">
              <div
                onClick={() => {
                  router.push({
                    pathname: "/pages/auth",
                    query: { type: "register" },
                  });
                }}
              >
                {"Sign Up"}
              </div>
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default Desktop;
