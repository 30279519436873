import ActionTypes from "@redux/actionsTypes";

export const setModalState = (state : boolean) => {
  return (dispatch: any) => {
    dispatch({
      type: ActionTypes.ContactUs.CHANGE_MODAL_STATE,
      payload : state
    });
  };
};
