export const resources = [
  {
    text: "How it works",
    page: "/pages/how-it-works",
    link: "how-it-works",
  },
  {
    text: "Services",
    page: "/pages/services",
    link: "services",
  },
  {
    text: "Pricing",
    page: "/pages/pricing",
    link: "pricing",
  },
  {
    text: "Blog",
    page: "https://remotederm.ca/blog/",
    link: "blogs",
  },
  {
    text: "FAQ",
    page: "/pages/faq",
    link: "contact-us",
  },
];

export const skinConditions = [
  {
    text: "Acne - Dermatology",
    link: "https://remotederm.ca/conditions/acne",
  },
  {
    text: "Hair Loss - Dermatology",
    link: "https://remotederm.ca/conditions/hair-loss",
  },
  {
    text: "Eczema - Dermatology",
    link: "https://remotederm.ca/conditions/eczema",
  },
  {
    text: "Psoriasis - Dermatology",
    link: "https://remotederm.ca/conditions/psoriasis",
  },
  {
    text: "Rosacea - Dermatology",
    link: "https://remotederm.ca/conditions/rosacea",
  },
  {
    text: "Nail Fungus - Dermatology",
    link: "https://remotederm.ca/conditions/nail",
  },
  {
    text: "More Conditions",
    link: "https://remotederm.ca/conditions",
  },
];

export const company = [
  {
    text: "RemoteDerm",
    link: "/pages/about-us",
  },
  {
    text: "Contact Us",
    link: "/pages/contact-us",
  },
  {
    text: "RemoteDerm Consent",
    link: "/pages/consent",
  },
  {
    text: "Terms and Conditions",
    link: "/pages/terms",
  },
  {
    text: "Privacy Policy",
    link: "/pages/policy",
  },
  {
    text: "Cookie Policy",
    link: "/pages/cookies",
  },
];
