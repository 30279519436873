import ActionTypes from "@redux/actionsTypes";
import { ConditionModal } from "./types";
const initialState: ConditionModal = {
  show: false,
  type: 'terms'
};
export const Conditions = (
  state: ConditionModal = initialState,
  action: any
): ConditionModal => {
  switch (action.type) {
    case ActionTypes.TermsPolicy.TOGGLE_CONDITION_MODAL:
      let show = state.show
      return {
        show: !show,
        type: action.payload
      };
    default:
      return state;
  }
};
