import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { IProps } from "./types";
import Header from "../header";
import Footer from "../footer";
import Video from "./video";
import TermsPolicy from "./termsPolicy";
import ContactUs from "./contactUs";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
// import { Breadcrumbs } from "@components/breadcrumbs";

const Layout = ({ children }: IProps) => {
  const [expanded, setExpanded] = useState(false);
  const { show } = useSelector((state: RootState) => state.Video);
  const condition = useSelector((state: RootState) => state.Conditions);
  const contactUs = useSelector((state: RootState) => state.ContactUs);
  return (
    <>
      {show && <Video />}
      {condition.show && <TermsPolicy />}
      {contactUs.show && <ContactUs />}
      <Header expanded={expanded} setExpanded={() => setExpanded(!expanded)} />
      {/* <main className="max-w-screen-2xl mx-auto mt-64 lg:mt-40 relative"> */}
      {/* normal header: */}
      <main className="max-w-screen-2xl mx-auto mt-20 lg:mt-36 relative">
        {/* <Breadcrumbs /> */}
        <div
          className={`flex lg:hidden absolute transition-all duration-500 ${
            expanded ? "opacity-50 h-full w-full " : "opacity-0"
          }  opacity-50 bg-gray-400`}
        />
        {children}
      </main>
      <Footer />
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={4}
      />
    </>
  );
};

export default Layout;
