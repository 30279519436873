import React, { useState } from "react";
import { expandCard } from './types'

const ExpandCard = (props: expandCard) => {
  return (
    <div className="flex flex-col mt-2.5 w-full lg:w-auto">
      <div
        onClick={() => props.setExpanded()}
        className="flex flex-row items-center w-full lg:w-auto justify-between mb-4 cursor-pointer lg:cursor-auto">
        <span className="font-sans-pro-semi-bold color-150910-secondary-1 font-semibold font-size-18">{props.title}</span>
        <img
          alt="Scroll down"
          src={'https://cdn.remotederm.ca/icons/footer/arrow-down.webp'}
          className={`w-6 h-6 block lg:hidden transform transition-transform duration-500 ${props.expanded ? '-rotate-180' : 'rotate-0'}`} />
      </div>
      <div className={`flex flex-col lg:h-auto transition-all duration-500 ${props.expanded ? 'h-44' : 'h-0'} overflow-hidden lg:overflow-auto`}>
        {props.children}
      </div>
    </div>
  );
};

export default ExpandCard;
