import toast from "@components/toast";
import Params from '@utilities/param'
import { URLS } from './index'

const errorHandler = async (err: any) => {
  let errorStatus = err?.response?.status ? err.response.status : 500;
  let message = err?.response?.data?.message;
  return new Promise(() => {
    if (err) {
      //console.log("cancel connection", err?.response);
      if (err?.response?.headers) {
        if (err?.response?.headers['x-reason']) {
          toast("error", err?.response?.headers['x-reason']);
        }
      }
      else if (err.toJSON().message === "Network Error") {
        if (err.toJSON()?.config?.url === URLS.AUTH.login) {
          toast("error", "Wrong user name or password");
        }
        if (err.toJSON()?.config?.url === URLS.AUTH.forgetPassword) {
          toast("error", "Email does not exist");
        }
        //console.log("no internet connection");
        // const dispatch = store.dispatch;
        // setInternetModal(true)(dispatch);
      } else if (!err?.response) {
      } else {
        switch (errorStatus) {
          case 409:
            toast("error", "Email has been already taken");
            break;
          case 400:
            break;
          case 401:
            localStorage.removeItem(Params.LOCAL_STORAGE.PATIENT_INFO)
            break;
          case 503:
            toast("error", "Sorry! Server Error. Please try again.");
            break;
          case 500:
            toast("error", "Sorry! Server Error. Please try again.");
            break;
          case 777:
            toast("error", 'Token Not In Form Or Is None');
            break;
          case 778:
            toast("error", 'Google Token Is Invalid');
            break;
          case 779:
            toast("error", 'Facebook Token Is Invalid');
            break;
          case 703:
            toast("error", 'Email Address Already Registered');
            break;
          case 763:
            toast("error", 'Email Is None');
            break;
          case 762:
            toast("error", 'password Is None');
            break;
          case 725:
            toast("error", 'Incorrect Email Or Password');
            break;
          case 409:
            toast("error", 'This Email Is Taken');
            break;
          case 873:
            toast("error", 'Patient Is Suspend');
            break;
          case 701:
            toast("error", 'Token Expired');
            break;
          case 702:
            toast("error", 'Malformed Access Token');
            break;

          default:
            //console.log(errorStatus)
            toast("error", 'unknown error happened')
            break;
        }
      }
    } else {
      throw err;
    }
  });
};
export default errorHandler;
