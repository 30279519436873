const Params = {
  LOCAL_STORAGE: {
    PATIENT_INFO: "patient_info",
    GOOGLE_TAGS: "google_tags",
  },
  COOKIES: {
    TOKEN: "token",
    TOKEN_REMOTE_PATIENT: "token-remote-patient",
    GA_CLIENT_ID: "_ga",
  },
};

export default Params;
