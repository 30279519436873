/** @format */

const ActionTypes = {
  auth: {
    SAVE_TOKEN: "SAVE_TOKEN",
  },
  Video: {
    TOGGLE_MODAL: "TOGGLE_MODAL",
  },
  ContactUs: {
    CHANGE_MODAL_STATE: "CHANGE_MODAL_STATE",
  },
  TermsPolicy: {
    TOGGLE_CONDITION_MODAL: "TOGGLE_CONDITION_MODAL",
  },
  Internet: {
    SHOW_NO_CONNECTION_MODAL: "SHOW_NO_CONNECTION_MODAL",
    HIDE_NO_CONNECTION_MODAL: "HIDE_NO_CONNECTION_MODAL",
  },
};

export default ActionTypes;
