import ActionTypes from "@redux/actionsTypes";
import { InternetModal } from "./types";
const initialState: InternetModal = {
  showNoInternet: false,
};
export const Internet = (
  state: InternetModal = initialState,
  action : any
): InternetModal => {
  switch (action.type) {
    case ActionTypes.Internet.SHOW_NO_CONNECTION_MODAL:
      return {
        ...state,
        showNoInternet: true,
      };
    case ActionTypes.Internet.HIDE_NO_CONNECTION_MODAL:
      return {
        ...state,
        showNoInternet: false,
      };

    default:
      return state;
  }
};
