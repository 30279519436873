import React, {useEffect} from "react";
import { AppProps } from "next/app";
import "tailwindcss/tailwind.css";
import "@styles/main.scss";
import { Provider } from "react-redux";
import store from "@redux/store";
import Layout from "@components/layout";
import "nprogress/nprogress.css";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Head from "next/head";
import { CookiesProvider } from 'react-cookie';
import Params from '@utilities/param'
import { GoogleOAuthProvider } from "@react-oauth/google";
import TagManager, {TagManagerArgs} from 'react-gtm-module';
Router.events.on("routeChangeStart", (url) => {
  const user = localStorage.getItem(Params.LOCAL_STORAGE.PATIENT_INFO);
  if (url === "/pages/auth?type=login" || url === "/pages/auth?type=register") {
    if (user) {
      Router.push("/");
    }
  }
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());

function RemoteDerm({ Component, pageProps }: AppProps): JSX.Element {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID || "",
    auth: process.env.REACT_APP_GTM_AUTH || "",
    preview: process.env.REACT_APP_GTM_PREVIEW || "",
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
    <Provider store={store}>
      <Head>
        <link rel="preconnect" href="https://cdn.remotederm.ca" />
        <link rel="preconnect" href="https://api.remotederm.ca" />
        <meta charSet="iso-8859-2" className="next-head" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1"
        />
        <meta
          property="og:title"
          content="Online Dermatologist Canada | No Referral Needed - RemoteDerm"
        />
        <meta
          property="og:site_name"
          content="Online Dermatologist Canada | No Referral Needed - RemoteDerm"
        />
        <meta
          property="og:description"
          content="RemoteDerm offers the best online dermatology consultations in Canada without a referral. Get treatment and take care of your skin, hair and nail 24/7."
        />
        <meta property="og:image" content="https://cdn.remotederm.ca/images/favicon.ico" />
        <link rel="SHORTCUT ICON" href="https://cdn.remotederm.ca/images/favicon.ico" />
        <meta
          name="description"
          content="RemoteDerm offers the best online dermatology consultations in Canada without a referral. Get treatment and take care of your skin, hair and nail 24/7."
        />
        <meta name="rating" content="Health" />
        <meta name="robots" content="index, follow" />
        <meta content="index" name="googlebot" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta
          name="apple-mobile-web-app-title"
          content="Online Dermatologist Canada | No Referral Needed - RemoteDerm"
        />
        <meta http-esquiv="content-security-policy" />
        <title>
          Online Dermatologist Canada | No Referral Needed - RemoteDerm
        </title>
        <link rel="app-touch-startup-image" href="https://cdn.remotederm.ca/images/favicon.ico" />
        <link rel="app-touch-icon" href="https://cdn.remotederm.ca/images/favicon.ico" sizes="48x48" />
        <link rel="app-touch-icon" href="https://cdn.remotederm.ca/images/favicon.ico" sizes="72x72" />
        <link rel="app-touch-icon" href="https://cdn.remotederm.ca/images/favicon.ico" sizes="96x96" />
        <link rel="app-touch-icon" href="https://cdn.remotederm.ca/images/favicon.ico" sizes="144x144" />
        <link rel="app-touch-icon" href="https://cdn.remotederm.ca/images/favicon.ico" sizes="168x168" />
        <link rel="app-touch-icon" href="https://cdn.remotederm.ca/images/favicon.ico" sizes="192x192" />
        <link rel="app-touch-icon" href="https://cdn.remotederm.ca/images/favicon.ico" sizes="256x256" />
        <link rel="app-touch-icon" href="https://cdn.remotederm.ca/images/favicon.ico" sizes="512x512" />
        <meta name="theme-color" content="#8b5cf6" />
      </Head>
      <CookiesProvider>
        <Layout>
          {/* @ts-ignore */}
          <Component {...pageProps} />
        </Layout>
      </CookiesProvider>
    </Provider>
    </GoogleOAuthProvider>
  );
}

export default RemoteDerm;
