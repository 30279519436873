/** @format */

import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import * as reducers from "./reducers";
const reducer = combineReducers(reducers);
const store = () => {
  const logger = createLogger();
  const allMiddleWares = [thunk, logger];
  const store = createStore(
    reducer,
    {},
    compose(applyMiddleware(...allMiddleWares))
  );
  return store;
};

export default store();
export type RootState = ReturnType<typeof reducer>;
