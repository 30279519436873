import ActionTypes from "@redux/actionsTypes";
import { ContactUsModal } from "./types";
const initialState: ContactUsModal = {
  show: false,
};
export const ContactUs = (
  state: ContactUsModal = initialState,
  action: any
): ContactUsModal => {
  switch (action.type) {
    case ActionTypes.ContactUs.CHANGE_MODAL_STATE:
      return { ...state, show: action.payload };
    default:
      return state;
  }
};
