import ActionTypes from "@redux/actionsTypes";

export const toggleConditionModal = (type: string) => {
  return (dispatch: any) => {
    dispatch({
      type: ActionTypes.TermsPolicy.TOGGLE_CONDITION_MODAL,
      payload: type
    });
  };
};
