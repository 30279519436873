import React, { useState } from "react";
import { label } from './types'
import {
  setModalState
} from "@redux/actions/contactUs";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Request, { URLS } from "@api";
import toast from "@components/toast";

const Label = (props: label) => {
  return <span className="font-sans-pro-semi-bold  color-150910-secondary-1 font-semibold font-size-14 mb-2">{props.title}</span>
}


const emailSchema = yup.string().email().required()

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = (data: any) => request(data);
  const dispatch = useDispatch();

  async function request(data: any) {
    setLoading(true)
    const response = await Request.post(URLS.HOME.contact, data) as any
    if (response) {
      toast("success", "Thanks , Your message successfully sent ");
      setModalState(false)(dispatch)
    }
    setLoading(false)
  }

  return (
    <div className="fixed z-50 bg-black bg-opacity-80 w-screen h-screen top-0 left-0 flex justify-center items-center">
      <div className="md:h-5/6 overflow-y-scroll px-6 py-6 h-full w-full  md:w-3/5 lg:w-2/5 bg-white rounded-none md:rounded-lg">
        <div
          className="cursor-pointer ml-auto cursor-pointer h-10 w-10 flex items-center justify-center rounded-full"
          onClick={() => setModalState(false)(dispatch)}>
          <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
        </div>
        <div className="mx-auto flex items-center justify-center mb-10">
          <span className="font-sans-pro-regular color-150910-secondary-1 font-size-24">{'Contact Us: '}<span className="font-size-12">{'You can contact RemoteDerm here'}</span></span>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full flex-col pb-4">
            <Label title={'First Name'} />
            <div className="w-full h-10 rounded-lg border border-gray-200 overflow-hidden">
              <input
                placeholder="First Name"
                className="h-full w-full px-4 font-size-14 font-sans-pro-regular color-150910-secondary-1"
                {...register("firstName", { required: true })} />
            </div>
            {errors.firstName && <span className="font-size-14 font-sans-pro-regular color-c05e6a  mt-1">Input your first name</span>}
          </div>
          <div className="flex w-full flex-col pb-4">
            <Label title={'Last Name'} />
            <div className="w-full h-10 rounded-lg border border-gray-200 overflow-hidden">
              <input
                placeholder="Last Name"
                className="h-full w-full px-4 font-size-14 font-sans-pro-regular color-150910-secondary-1"
                {...register("lastName", { required: true })} />
            </div>
            {errors.lastName && <span className="font-size-14 font-sans-pro-regular color-c05e6a  mt-1">Input your last name</span>}
          </div>
          <div className="flex w-full flex-col pb-4">
            <Label title={'Email'} />
            <div className="w-full h-10 rounded-lg border border-gray-200 overflow-hidden">
              <input
                type="email"
                placeholder="Your Email"
                className="h-full w-full px-4 font-size-14 font-sans-pro-regular color-150910-secondary-1"
                {...register("email", {
                  validate: value => emailSchema.isValid(value)
                })} />
            </div>
            {errors.email && <span className="font-size-14 font-sans-pro-regular color-c05e6a  mt-1">Input valid email</span>}
          </div>
          <div className="flex w-full flex-col pb-4">
            <Label title={'Subject'} />
            <div className="w-full h-10 rounded-lg border border-gray-200 overflow-hidden">
              <select
                defaultValue={'feedback'}
                placeholder="Subject"
                className="h-full w-full px-4 font-size-14 font-sans-pro-regular color-150910-secondary-1"
                {...register("subject", { required: true })}>
                <option value="Feedback">Feedback</option>
                <option value="Partnership">Partnership</option>
                <option value="General questions">General questions</option>
                <option value="Issue with the Site">Issue with the Site</option>
                <option value="Issue with my account">Issue with my account</option>
                <option value="Complement or Complain">Complement or Complain</option>
              </select>
            </div>
            {errors.subject && <span className="font-size-14 font-sans-pro-regular color-c05e6a  mt-1">Select subject</span>}
          </div>
          <div className="flex w-full flex-col pb-4">
            <Label title={'Title'} />
            <div className="w-full h-10 rounded-lg border border-gray-200 overflow-hidden">
              <input
                placeholder="Title"
                className="h-full w-full px-4 font-size-14 font-sans-pro-regular color-150910-secondary-1"
                {...register("title", { required: true })} />
            </div>
            {errors.title && <span className="font-size-14 font-sans-pro-regular color-c05e6a  mt-1">Input your title</span>}
          </div>
          <div className="flex w-full flex-col pb-4">
            <Label title={'Message'} />
            <div className="w-full h-32 rounded-lg border border-gray-200 overflow-hidden">
              <textarea
                placeholder="Message"
                className="pt-4 h-32 w-full px-4 font-size-14 font-sans-pro-regular color-150910-secondary-1"
                {...register("body", { required: true })} />
            </div>
            {errors.body && <span className="font-size-14 font-sans-pro-regular color-c05e6a  mt-1">Input your message</span>}
          </div>
          <div className="h-10 w-32 mx-auto mb-6 rounded-lg  bg-color-c56183-eye-catching overflow-hidden">
            {!loading ?
              <input className="h-full w-full bg-color-c56183-eye-catching text-white" type="submit" value="send" />
              :
              <div className="h-full w-full flex justify-center items-center">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
            }
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
