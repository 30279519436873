import React from "react";
import { IProps } from './types'
import {
  toggleVideo
} from "@redux/actions/video";
import { useDispatch } from "react-redux";
const Video = () => {
  const dispatch = useDispatch();
  return (
    <div className="fixed z-50 bg-black bg-opacity-80 w-screen h-screen top-0 left-0 flex justify-center items-center">
      <div
        onClick={() => toggleVideo()(dispatch)}
        className="absolute z-10 left-4 top-4 bg-white rounded-lg h-10 px-6 flex justify-center items-center cursor-pointer bg-color-c56183-eye-catching text-white">
        {'Close'}
      </div>
      <video
        controlsList="nodownload"
        controls
        loop
        preload="metadata"
        width="100%"
        id={`video-landing`}
        autoPlay={true}
        className="w-full md:w-4/5  lg:w-3/5  max-w-screen-2xl mx-auto object-center cursor-pointer"
      >
        <source src={'https://cdn.remotederm.ca/landing_assets/landing.mp4'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
